@tailwind base;
@tailwind components;
@tailwind utilities;

/*! purgecss start ignore */
@import "prism.css";
/*! purgecss end ignore */

/* Custom styles */
@layer components {
  /* Black text on autofill, PR: https://github.com/AvaProtocol/oak-website/pull/871 */
  .autofill-input-text-black:-webkit-autofill,
  .autofill-input-text-black:-webkit-autofill:hover,
  .autofill-input-text-black:-webkit-autofill:focus,
  .autofill-input-text-black:-webkit-autofill:active {
    -webkit-text-fill-color: #000;
  }
}

/* Custom styles for /docs; make sure they don’t colide with the styles of other routes */
.heading-anchor {
  display: inline;
  margin-left: 0.5rem;
  text-decoration: none;

  span {
    text-decoration: none;
    text-underline-offset: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}
